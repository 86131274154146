import * as React from "react"
import "../assets/fonts/fonts.css"
import Splashscreen from "./splashscreen"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="SMART BARTENDER | Make awesome cocktails." />
        <title>SMART BARTENDER | Make Awesome Cocktails</title>
        <link rel="canonical" href="https://smart-bartender.de" />
      </Helmet>
      <Splashscreen />
    </>
  )
}

export default IndexPage
